import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-wbce-sidenav',
    templateUrl: './wbce-sidenav.component.html',
    styleUrls: ['./wbce-sidenav.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('*', style({ 'overflow-x': 'hidden' })),
            state('void', style({ 'overflow-x': 'hidden' })),
            transition('* => void', [
                style({ width: '*' }),
                animate(250, style({ width: 0 })),
            ]),
            transition('void => *', [
                style({ width: '0' }),
                animate(250, style({ width: '*' })),
            ]),
        ]),
    ],
})
export class WbceSidenavComponent implements OnInit {
    openNavBar: boolean = true;

    @Input()
    public set menuOpened(value: boolean) {
        document.documentElement.style.setProperty(
            '--alignment-left',
            value ? 'col 3' : 'col 1'
        );
        this.openNavBar = value;
    }

    @Input() integrated: boolean;

    constructor() {}

    ngOnInit(): void {}

    toggle() {
        this.menuOpened = !this.menuOpened;
    }
}
