import { inject, Injectable } from '@angular/core';
import {
    ActivatedRoute,
    NavigationEnd,
    ParamMap,
    Router,
} from '@angular/router';
import { ReplaySubject, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { contexts } from '../enums/contexts';
import { navigationGlobal, navigationService } from '../enums/navigation';
import { statusSupervisor } from '../enums/status';
import { technos, technosName } from '../enums/technos';
import { ALL_SERVICES } from '../home/left-navigation-list/left-navigation-list.component';
import { Project } from '../models/project';
import { Commit, deploymentStates, Service } from '../models/service';
import { API_SERVICE_TOKEN } from './api.service';
import { WorkspaceManagerService } from './workspace-manager.service';

@Injectable({
    providedIn: 'root',
})
export class WbceServiceManagerService {
    subs: Subscription[] = [];
    currentProject: Project;
    currentProject$ = new ReplaySubject(1);

    currentService: Service | undefined;
    currentContext: contexts;
    contexts = [];

    isInLoad = false;

    private router = inject(Router);
    private api = inject(API_SERVICE_TOKEN);
    private route = inject(ActivatedRoute);
    private workspaceService = inject(WorkspaceManagerService);

    constructor() {
        this.subs.push(
            this.router.events
                .pipe(filter((e) => e instanceof NavigationEnd))
                .subscribe(this.onRouteChange.bind(this))
        );

        this.subs.push(
            this.workspaceService.projects$.subscribe(
                this.onWorkspaceChange.bind(this)
            )
        );
    }

    ngOnDestroy() {
        //tant que le service est build en root, est inutile
        for (let s of this.subs) {
            s.unsubscribe();
        }
    }

    onWorkspaceChange() {
        // update - if before route change will update later
        const project = this.workspaceService.projects.find(
            (p) => p._id === this.currentProject?._id
        );
        if (!project) return;

        const service = project.services.find(
            (s) => s._id === this.currentService?._id
        );
        if (!service) return;

        if (
            JSON.stringify(project) !== JSON.stringify(this.currentProject) ||
            JSON.stringify(service) !== JSON.stringify(this.currentService)
        ) {
            // change and notify
            this.currentProject = project;
            this.currentService = service; // not find return change path

            this.currentProject$.next({});
        }
    }

    onRouteChange(paramMap: ParamMap) {
        this.reloadFromPath(true);
    }

    reloadServices() {
        return this.workspaceService.reloadProject(this.currentProject); // refresh waiting api
    }

    // ACCESSEUR
    getServices() {
        return this.currentProject?.services;
    }

    getServicesToDisplay() {
        return this.currentService ? [this.currentService] : this.getServices();
    }

    // reload info from route
    reloadFromPath(fromRoute: boolean) {
        let context: contexts;
        let lastPartOfUrl: string = '';
        let retrieveParam: boolean = false;
        let projectName: string = '';
        let serviceName: string = '';

        let path = this.route.children[0]?.snapshot?.url[0].path;
        if (path !== 'home') return;

        const retrieveDataFromRoute = (route: ActivatedRoute) => {
            const params = route.snapshot.params;
            if (
                params['projectName'] !== undefined &&
                params['context'] !== undefined &&
                params['serviceName'] !== undefined
            ) {
                context = params['context'];
                projectName = params['projectName'];
                serviceName = params['serviceName'];
                retrieveParam = true;
                //  return true;
            }
            if (route.children.length) {
                return retrieveDataFromRoute(route.children[0]);
            } else {
                lastPartOfUrl = route.snapshot.url[0]?.path;
                return retrieveParam; //end
            }
        };
        if (!retrieveDataFromRoute(this.route)) {
            this.router.navigate([`/workspace`]);
            return;
        }
        if (!this.workspaceService.projects) {
            this.router.navigate([`/workspace`]);
            return;
        }
        const project = this.workspaceService.projects.find(
            (p) => p.projectName === projectName
        );
        if (!project) {
            this.router.navigate([`/workspace`]);
            return;
        }

        let toRedirect = false;
        let serviceNameToRedirect = serviceName;
        let contextToRedirect = context;

        let service = project.services.find(
            (s) => s.calculatedDisplayName === serviceName
        );
        if (!service && serviceName !== ALL_SERVICES) {
            if (!service) {
                let oldName =
                    this.currentService?.calculatedDisplayName === serviceName;

                if (oldName)
                    service = project.services.find(
                        (s) => s._id === this.currentService?._id
                    );
            }

            if (!service) {
                // unknown service - redirect to overview in all services
                serviceNameToRedirect = ALL_SERVICES;
                lastPartOfUrl = navigationGlobal[0].route;
                toRedirect = true;
            } else {
                // service with id matching same path
                serviceNameToRedirect = service.calculatedDisplayName;
                toRedirect = true;
            }
        }

        // make a specific router - change path if needed
        if (serviceName === ALL_SERVICES) {
            if (!navigationGlobal.find((ns) => ns.route === lastPartOfUrl)) {
                toRedirect = true;
                lastPartOfUrl = navigationGlobal[0].route;
            }
        } else {
            if (!navigationService.find((ns) => ns.route === lastPartOfUrl)) {
                toRedirect = true;
                lastPartOfUrl = navigationService[0].route;
            }
        }

        this.contexts = project.getContexts();
        const authContext = this.contexts.indexOf(context) > -1;
        if (!authContext) {
            if (this.contexts.length > 0) {
                toRedirect = true;
                contextToRedirect = this.contexts[0];
            }
        }

        if (toRedirect) {
            if (contextToRedirect) {
                this.router.navigate([
                    `/home/${projectName}/${serviceNameToRedirect}/${contextToRedirect}/${lastPartOfUrl}`,
                ]);
            } else {
                this.router.navigate([`/home/`]);
            }
        }

        if (
            fromRoute && // on data change need to reload project
            this.currentProject === project &&
            this.currentContext === context &&
            this.currentService === service
        ) {
            // no change
            return;
        }
        this.currentProject = project;
        this.currentService = service;
        this.currentContext = context as contexts;

        this.currentProject$.next({});
        return;
    }

    getContextToDeploy(
        service: Service,
        commit: Commit,
        context: contexts | string
    ) {
        let ctxtToDeploy;

        //#context
        switch (commit.commitId) {
            case service.getContextDoc(context)?.deployment?.currentCommit:
                ctxtToDeploy = undefined;
                break;

            default:
                ctxtToDeploy = context;
                // ctxtToDeploy = this.currentContext
                break;
        }
        commit.ctxtToDeploy = ctxtToDeploy;
    }

    deployService(service: Service, commit: Commit) {
        return this.api.deploy(service, commit, this.currentContext);
    }

    // SUPERVISOR
    getStatus(project: Project, context: contexts | string) {
        let inProgress = false;
        let error = false;
        let success = false;
        let waiting = false;
        let waiting_first = false;
        project.services?.forEach((s) => {
            inProgress =
                inProgress ||
                this.getStatusService(s, context) ===
                    statusSupervisor.IN_PROGRESS;
            waiting =
                waiting ||
                this.getStatusService(s, context) === statusSupervisor.SLEEPING;
            waiting_first =
                waiting_first ||
                this.getStatusService(s, context) ===
                    statusSupervisor.WAITING_FIRST_DEPLOYMENT;
            error =
                error ||
                this.getStatusService(s, context) === statusSupervisor.ERROR;
            success =
                success ||
                this.getStatusService(s, context) === statusSupervisor.SUCCESS;
        });
        if (inProgress) return statusSupervisor.IN_PROGRESS;
        if (error) return statusSupervisor.ERROR;
        if (waiting_first) return statusSupervisor.WAITING_FIRST_DEPLOYMENT;
        if (waiting) return statusSupervisor.SLEEPING;
        return statusSupervisor.SUCCESS;
    }

    getStatusService(
        service: Service,
        context: contexts | string
    ): statusSupervisor {
        // if deployment or build in progress status
        // status.IN_PROGRESS
        // if no deployment sleeping and no build or build success -> orange (second solution not normal)
        // if no deployment and error in build => error
        //#context

        let contextDoc = service?.getContextDoc(context);
        let deployments = contextDoc?.deployments;
        let mainCycle = contextDoc?.mainCycle;
        let inProgress = false;
        let error = false;
        let waiting = false;
        let waiting_first = false;
        // use service state later
        if (deployments && deployments.length > 0) {
            let lastDeployment = deployments[deployments.length - 1];
            if (lastDeployment.state === deploymentStates.IN_PROGRESS)
                inProgress = true;
            if (lastDeployment.state === deploymentStates.ERROR) error = true;
        } else {
            // if no deployments, waiting for first deployment - only front ?
            waiting = true;
            waiting_first = true;
        }
        if (mainCycle) {
            inProgress =
                inProgress || mainCycle.state === deploymentStates.IN_PROGRESS;
            error =
                error ||
                (deployments &&
                    deployments.length === 0 &&
                    mainCycle.state === deploymentStates.ERROR);
        }

        if (inProgress) return statusSupervisor.IN_PROGRESS; // build or deployment
        if (error) return statusSupervisor.ERROR; // for the first deployment
        if (waiting_first) return statusSupervisor.WAITING_FIRST_DEPLOYMENT;
        if (waiting) return statusSupervisor.SLEEPING; // for the first deployment
        return statusSupervisor.SUCCESS;
    }

    getLastDeployment(project: Project, context: contexts): Date {
        let lastDate: Date = new Date('2000-01-01');
        project.services.forEach((s) => {
            //#context
            const contextDoc = s.getContextDoc(context);
            let date: Date = new Date(contextDoc?.deployment?.deployedAt);
            if (date > lastDate) {
                lastDate = date;
            }
        });

        return lastDate;
    }

    getLastDeploymentService(service: Service, context: contexts): Date {
        return new Date();
    }

    // REPORT
    getReport() {
        let reports = [];
        let services = this.currentProject?.services;
        let serviceFront = services.find(
            (s) =>
                s.techno.id == technos.PLASMIC ||
                s.techno.id == technos.WEWEB ||
                s.techno.id == technos.ANGULAR
        ); //TODO use front tag
        const contextDoc = serviceFront?.getContextDoc(this.currentContext);
        if (contextDoc?.reports) {
            contextDoc.reports.map((report) =>
                reports.push({
                    src: this.getReportUrl(report._id, serviceFront),
                    createdAt: report.createdAt,
                })
            );
        }
        return reports.sort(
            (a, b) => -1 + 2 * Number(b.createdAt > a.createdAt)
        );
    }

    getReportUrl(reportId: string, serviceFront: Service) {
        return this.api.reportUrlForIframe(
            serviceFront._id,
            this.currentContext,
            reportId
        );
    }

    generateReport() {
        let services = this.currentProject?.services;
        const idService = services.findIndex(
            (s) =>
                s.techno.id == technos.PLASMIC ||
                s.techno.id == technos.WEWEB ||
                s.techno.id == technos.ANGULAR
        ); //TODO
        let service = this.currentProject.services[idService];
        return this.api.generateReport(service._id, this.currentContext).pipe(
            tap((report: any) => {
                service.getContextDoc(this.currentContext).reports.push(report);
                this.currentProject$.next({});
            })
        );
    }

    getServiceNameById(serviceId: string): string {
        if (serviceId) {
            let services = this.currentProject.services;
            let service = services.find((s) => s._id == serviceId);
            return service?.calculatedDisplayName;
        }
        return 'workers';
    }

    getTechnoNameById(serviceId: string) {
        if (serviceId) {
            let services = this.currentProject.services;
            let service = services.find((s) => s._id == serviceId);
            if (service) return technosName[service.techno.id];
        }
        return 'wbce'; // project logo later
    }

    updateAccess(role: any) {
        this.api.updateAccess(role).pipe(
            tap(() => {
                this.currentProject$.next({});
            })
        );
    }
}
