import { Injectable, inject } from '@angular/core';
import { Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { contexts, contextsDisplayNames } from '../enums/contexts';
import { errorCodes } from '../enums/error-codes';
import { technos } from '../enums/technos';
import { Project } from '../models/project';
import { Service, deploymentStates } from '../models/service';
import { User } from '../models/user';
import { Alert, AlertCode, AlertService, AlertType } from './alert.service';
import { API_SERVICE_TOKEN } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class WorkspaceManagerService {
    subs: Subscription[] = [];
    projects: Project[] = [];
    wbceProject: Project;
    workspaceName: string = 'My workspace';
    currentUser: User;

    nbMaxProject: number = 4;

    projects$ = new ReplaySubject(1);

    intervals: any[] = [];
    intervalsFreq: number = 10 * 1000;

    private auth = inject(AuthService);
    private alertService = inject(AlertService);
    private api = inject(API_SERVICE_TOKEN);

    constructor() {
        this.subs.push(
            this.auth.currentUser$.subscribe(this.onUserChange.bind(this))
        );

        this.intervals.push(
            setInterval(() => {
                this.reloadProjects().subscribe();
            }, this.intervalsFreq)
        );
    }

    ngOnDestroy() {
        for (let s of this.subs) {
            s.unsubscribe();
        }
    }

    onUserChange(user: User) {
        if (user) {
            this.currentUser = user;
            this.projects = user.projects
                .filter(
                    (p) =>
                        p.projectName != 'wbce' && p.projectName != 'webcapsule'
                )
                .sort((a, b) => (a._id > b._id ? 1 : -1));
            this.wbceProject = user.projects.find(
                (p) => p.projectName === 'wbce' || p.projectName == 'webcapsule'
            );
            this.addWorkspaceStatusNotification();

            this.projects$.next(this.projects);
        }
    }

    reloadProjects(): Observable<void> {
        return this.auth.reLoadUser(); // reload projects & users
    }

    reloadProject(project: Project): Observable<void> {
        const index = this.projects.findIndex((p) => p._id === project._id);
        if (index >= 0) {
            return this.api.getProject(project).pipe(
                map((res: any) => {
                    this.projects[index] = Project.createFromApi(res?.project);
                    this.projects$.next(this.projects);
                })
            );
        }
        return of();
    }

    openInfisicalService() {
        let serviceInfisical = this.wbceProject.services.find(
            (s) => s.techno.id == technos.INFISICAL
        );

        if (serviceInfisical) {
            this.api
                .getUrl(serviceInfisical, contexts.GLOBAL)
                .subscribe((url) => {
                    if (url) window.open(url, '_blank');
                });
        }
    }

    // Add alert
    addServiceStatusNotification(
        projectName: string,
        service: Service,
        context: contexts | string
    ) {
        let contextDoc = service.getContextDoc(context);
        if (!contextDoc) return false;
        let mainCycle = contextDoc.mainCycle;
        let deployments = contextDoc.deployments;
        let lastDeployment =
            deployments &&
            deployments.length > 0 &&
            deployments[deployments.length - 1];

        let stateText = '';
        let filter = {
            serviceId: service._id,
            context: context,
        };
        let titre = projectName;
        if (service.techno)
            titre = titre + ' - ' + service?.calculatedDisplayName;
        titre = titre + ' - ' + contextsDisplayNames[context];
        let addRefreshTimer = false;
        let dontCheckDeploymentState = false;

        if (mainCycle) {
            //looking for step
            let message = 'Saving a version';
            let alertType = AlertType.ERROR_PERMANENT;
            let updateOnly = true;
            let state = mainCycle.state;
            let suffix = ' with a few warnings';

            try {
                const error = JSON.parse(mainCycle.message);
                if (error.code === errorCodes.NOTHING_TO_COMMIT) {
                    state = deploymentStates.WARNING;
                    suffix = ' and nothing to commit.';
                }
            } catch (error) {}

            switch (state) {
                case deploymentStates.IN_PROGRESS:
                    alertType = AlertType.PROGRESS;
                    message = message + '.';
                    addRefreshTimer = true;
                    updateOnly = false;

                    // for mainCycle : look at deployment state
                    if (
                        lastDeployment &&
                        lastDeployment.state === deploymentStates.IN_PROGRESS
                    ) {
                        message = 'Deployment in progress';
                        dontCheckDeploymentState = true;
                    }

                    break;
                case deploymentStates.WARNING:
                    alertType = AlertType.WARNING;
                    message = message + suffix;
                    break;
                case deploymentStates.ERROR:
                    alertType = AlertType.ERROR_PERMANENT;
                    message = message + ' failed.';
                    break;
                case deploymentStates.SUCCESS:
                    alertType = AlertType.SUCCESS;
                    stateText = message + ' run successfully';
                    break;
                default:
                    alertType = AlertType.ERROR_PERMANENT;
                    message = message + ' failed.';
                    break;
            }

            let alert = new Alert(
                titre,
                AlertCode.ADD,
                alertType,
                filter,
                message
            );
            if (updateOnly) {
                this.alertService.updateIfExistAlert(alert);
            } else {
                this.alertService.addOrUpdateAlert(alert);
            }
        }

        if (lastDeployment && !dontCheckDeploymentState) {
            let alertType = AlertType.ERROR_PERMANENT;
            let updateOnly = true;
            let message = 'Deployment';

            switch (lastDeployment.state) {
                case deploymentStates.IN_PROGRESS:
                    alertType = AlertType.PROGRESS;
                    message = message + ' in progress.';
                    addRefreshTimer = true;
                    updateOnly = false;
                    break;
                case deploymentStates.ERROR:
                    alertType = AlertType.ERROR_PERMANENT;
                    message = message + ' failed.';
                    break;
                case deploymentStates.SUCCESS:
                    alertType = AlertType.SUCCESS;
                    stateText = message + ' run successfully';
                    break;
                default:
                    alertType = AlertType.ERROR_PERMANENT;
                    message = message + ' failed.';
                    break;
            }

            let alert = new Alert(
                titre,
                AlertCode.DEPLOY,
                alertType,
                filter,
                message
            );
            if (updateOnly) {
                this.alertService.updateIfExistAlert(alert);
            } else {
                this.alertService.addOrUpdateAlert(alert);
            }
        }

        return addRefreshTimer;
    }

    //temporary service notification before refreshing
    addTmpServiceStatusNotification(
        alertCode: AlertCode,
        projectName: string,
        service: Service,
        context: contexts,
        state: deploymentStates
    ) {
        let filter = {
            serviceId: service._id,
            context: context,
        };
        let titre = projectName;
        if (service.techno)
            titre = titre + ' - ' + service?.calculatedDisplayName;
        titre = titre + ' - ' + contextsDisplayNames[context];
        let subtitle =
            alertCode === AlertCode.ADD
                ? 'Starting saving a version.'
                : alertCode === AlertCode.MERGE
                  ? 'Starting merging.'
                  : 'Starting deployment.';
        let alert = new Alert(
            titre,
            alertCode,
            AlertType.PROGRESS,
            filter,
            subtitle
        ); // or save dependent of type
        this.alertService.addOrUpdateAlert(alert);
    }

    addWorkspaceStatusNotification() {
        // scan services to add to alert notifications of deployments
        this.projects.forEach((p) => {
            const ctxts = p.getContexts();
            p.services.forEach((s) => {
                for (const context of ctxts) {
                    this.addServiceStatusNotification(
                        p.projectName,
                        s,
                        context
                    );
                }
            });
        });
    }

    getProjectName(projectId: string) {
        const project = this.projects.find((p) => p._id === projectId);
        return project?.projectName || projectId;
    }
}
