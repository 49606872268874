<section id="container">
    <h1>Webcapsule: beta-test</h1>
    <div>In beta-test phase, several functionalities will be delivered.</div>
    <div>
        You can report bugs and suggest features by writing to:
        <a href="mailto: infos@webcapsule.io" type="mail">
            infos&#64;webcapsule.io
        </a>
    </div>
</section>
