export enum errorCodes {
    OTHER = -1,
    DATABASE_ERROR = 0,
    RESOURCE_NOT_FOUND = 1,
    NOT_ACCEPTABLE = 2,
    UNAUTHORIZED = 3,
    INVALID_SESSION = 4,
    RESOURCE_LOCKED = 5,
    RESOURCE_ALREADY_EXISTS = 6,
    NO_INFISICAL_CREDS = 7,
    NO_JOB_LOGS = 8,
    /**
     * creneau 300 à 399 reserve aux erreurs de build
     */
    DOCKERFILE_NOT_FOUND = 300,
    NOTHING_TO_COMMIT = 301,
    /**
     * creneau de 400 à 599 reserve au retour d'api. (proxy)
     */
    REQUEST_ERROR = 400,
    REQUEST_ERROR1 = 401,
    REQUEST_ERROR2 = 402,
    REQUEST_FORBIDDEN = 403,
    REQUEST_NOT_FOUND = 404,
    /***
     * creneau de 1000 à 2000 reserve aux operations directus
     * (manage this differently ?)
     */
    DIRECTUS_SNAPSHOT_CONFLICT = 1001,
}
