import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ClickOutsideDirective } from './directive/clickOutside.directive'; // directive pouvant être revu
import { DragDirective } from './directive/dragNDrop.directive';
import { HeaderComponent } from './header/header.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { MenuComponent } from './menu/menu.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { InformationDialogComponent } from './session-expired-dialog/information-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StepComponent } from './step/step.component';
import { TableComponent } from './table/table.component';
import { ToasterComponent } from './toaster/toaster.component';
import { WbceButtonComponent } from './wbce-button/wbce-button.component';
import { WbceSidenavComponent } from './wbce-sidenav/wbce-sidenav.component';

@NgModule({
    declarations: [
        HeaderComponent,
        InfoDialogComponent,
        WbceButtonComponent,
        MenuComponent,
        StepComponent,
        WbceSidenavComponent,
        TableComponent,
        ClickOutsideDirective,
        DragDirective,
        SpinnerComponent,
        ToasterComponent,
        NotificationsComponent,
        InformationDialogComponent,
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        RouterModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        OverlayModule,
        MatRippleModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatMenuModule,
    ],
    exports: [
        ClickOutsideDirective,
        HeaderComponent,
        StepComponent,
        TableComponent,
        WbceButtonComponent,
        SpinnerComponent,
        WbceSidenavComponent,
        ToasterComponent,
        DragDirective,
        NotificationsComponent,
        InformationDialogComponent,
    ],
})
export class SharedComponentsModule {}
