<mat-toolbar id="wbce-toolbar"> </mat-toolbar>

<div id="path" [ngClass]="fixed ? 'fixed' : ''">
    <a class="title" routerLink="/workspace">
        <img class="logo" [src]="app.picture" />
        <h3>{{ app.title }}</h3>
    </a>
    <div class="subtitle">
        <div class="notch-path theming-notch"></div>
        <span class="mat-body-2">{{ currentPathDescription?.title }}</span>
    </div>
</div>

<app-menu></app-menu>
